import request from '@/libs/request'

/**
 * 订单提交页面
 *
 * 购物车结算 订单提交页面数据获取
 **/
export function OrderConfirm(data) {
  return request({
    url: '/order/confirm',
    method: 'post',
    data
  })
}

/**
 * 购物车结算 订单提交页面数据提交
 **/
export function OrderPost(data) {
  return request({
    url: '/order/orderrefer',
    method: 'post',
    data
  })
}

/**
 * 立即购买 订单提交页面数据获取
 */
export function OrderBuyNow(data) {
  return request({
    url: '/order/buynow',
    method: 'post',
    data
  })
}

/**
 * 立即购买 订单提交页面数据提交
 **/
export function OrderBuyNowPost(data) {
  return request({
    url: '/order/buynowconfirm',
    method: 'post',
    data
  })
}

/**
 * 个人中心 订单列表
 **/
export function OrderListPost(data) {
  return request({
    url: '/order/orderlist',
    method: 'post',
    data
  })
}

/**
 * 个人中心 订单详情
 **/
export function OrderInfo(data) {
  return request({
    url: '/order/orderinfo',
    method: 'post',
    data
  })
}

/**
 * 个人中心 未支付 取消订单
 **/
export function OrderCancellation(data) {
  return request({
    url: '/order/cancellation',
    method: 'post',
    data
  })
}

/**
 * 个人中心 订单物流信息接口
 **/
 export function OrderLogistics(data) {
  return request({
    url: '/order/logistics',
    method: 'post',
    data
  })
}

/**
 * 个人中心 已支付 取消订单
 **/
 export function PayOrderCancellation(data) {
  return request({
    url: '/order/paycancellation',
    method: 'post',
    data
  })
}

/**
 * 个人中心 确认收货接口
 **/
 export function ReceivingOrder(data) {
  return request({
    url: '/order/receivingorder',
    method: 'post',
    data
  })
}

/**
 * 订单 申请售后页面 初始化数据
 **/
 export function AfterSale(data) {
  return request({
    url: '/order/aftersale',
    method: 'post',
    data
  })
}

/**
 * 订单 申请售后页面 数据提交
 **/
 export function AfterSaleUp(data) {
  return request({
    url: '/order/aftersaleup',
    method: 'post',
    data
  })
}

/**
 * 售后服务列表页 
 **/
 export function AfterSaleList(data) {
  return request({
    url: '/order/aftersalelist',
    method: 'post',
    data
  })
}

/**
 * 售后服务详情页
 **/
 export function AfterSaleInfo(data) {
  return request({
    url: '/order/aftersaleinfo',
    method: 'post',
    data
  })
}

/**
 * 退款退货售后服务发货 初始化接口
 **/
 export function AfterLogistics(data) {
  return request({
    url: '/order/afterlogistics',
    method: 'post',
    data
  })
}

/**
 * 退款退货售后服务发货页面 提交接口
 **/
 export function LogisticsUp(data) {
  return request({
    url: '/order/logisticsup',
    method: 'post',
    data
  })
}


