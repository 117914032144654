<template>
  <div class="refund-details" v-loading="loading">
    <div class="d-container">
      <!-- 仅退款 -->
      <ul class="d-refund" v-if="refundStatus == 1">
        <li class="h-item">
          <div class="h-num">1</div>
          <span class="h-text">买家申请仅退款</span>
        </li>
        <li class="h-item">
          <div class="h-num">2</div>
          <span class="h-text">卖家处理退款申请</span>
        </li>
        <li class="h-item">
          <div class="h-num">3</div>
          <span class="h-text">退款完毕</span>
        </li>
      </ul>
      <!-- 退货退款 -->
      <ul class="d-both" v-if="refundStatus == 2">
        <li class="h-item">
          <div class="h-num">1</div>
          <span class="h-text">买家申请退货退款</span>
        </li>
        <li class="h-item">
          <div class="h-num">2</div>
          <span class="h-text">卖家处理退货申请</span>
        </li>
        <li class="h-item">
          <div class="h-num">3</div>
          <span class="h-text">买家退货</span>
        </li>
        <li class="h-item">
          <div class="h-num">4</div>
          <span class="h-text">退款完毕</span>
        </li>
      </ul>
      <div class="d-content">
        <div class="c-refund">
          <div class="r-status">
            <div class="s-content">
              <div class="s-text">{{statustext}}</div>
              <div class="s-times">时间:<span>{{statustime}}</span></div>
            </div>
            <div class="s-btn">
              <div class="s-num" @click="handleLogisticsNum" v-if="info.type == 2 && info.status==1">
                填写退货物流单号
              </div>
              <div class="s-cancel" @click="revoke()" v-if="info.status == 1">
                撤销申请
              </div>
            </div>
          </div>
          <div class="r-money">
            退款总金额:
            <span class="m-text">￥{{ this.info.refundsum }} </span>
          </div>
          <div class="c-progress">
            <div class="p-txt">&nbsp;</div>
            <div class="p-content">
              <el-steps align-center :active="refundActive" finish-status="success">
                <el-step title="买家申请" description="2022-1-2 18:26"></el-step>
                <el-step title="卖家退款" description="2022-1-2 18:26"></el-step>
                <el-step title="退款成功" description="2022-1-2 18:26"></el-step>
              </el-steps>
            </div>
          </div>
        </div>
        <!-- 商品详情 -->
        <div class="c-goods">
          <div class="g-title">售后详情</div>
          <div class="g-content" v-if="info.Goods">
            <div class="g-img">
              <img :src="info.Goods.img" />
            </div>
            <div class="g-goodsName">
              <div>
                {{ info.Goods.goods_name }}
              </div>
              <div class="g-spec"></div>
            </div>
          </div>
          <div class="g-order">
            <ul class="o-title">
              <li>卖家:</li>
              <li>订单编号:</li>
              <li>成交时间:</li>
              <li>单价:</li>
              <li>邮费:</li>
              <li>商品总价:</li>
            </ul>
            <ul class="o-data" v-if="info.Order">
              <li>{{ info.store_name }}</li>
              <li class="o-item">{{ info.Order.order_no }}</li>
              <li>{{ info.Order.delivery_time }}</li>
              <li>
                ￥{{ info.Goods.goods_price }}*{{ info.Goods.goods_num }}(数量)
              </li>
              <li>￥0.00</li>
              <li>￥{{ info.Goods.goods_price * info.Goods.goods_num }}</li>
            </ul>
          </div>
          <div class="g-refund">
            <ul class="r-title">
              <li>退款编号：</li>
              <li>退款金额：</li>
              <li>原因:</li>
              <li>要求:</li>
              <li>货物状态:</li>
            </ul>
            <ul class="r-data">
              <li>{{ info.order_no }}</li>
              <li>￥{{ info.refundsum }}</li>
              <li v-if="info.type == 1">{{ info.reason | refundOptions }}</li>
              <li v-if="info.type == 2">{{ info.reason | bothOptions }}</li>
              <li>{{ info.type == 1 ? '仅退款':'退货退款' }}</li>
              <li>已寄回</li>
            </ul>
          </div>
          <div class="g-title">退货地址信息</div>
          <div class="g-Logistics">
            <ul class="l-title">
              <li>收货人:</li>
              <li>联系电话:</li>
              <li>收货地址:</li>
            </ul>
            <ul class="l-data">
              <li>{{ addr.username }}</li>
              <li>{{ addr.phone }}&nbsp;</li>
              <li class="d-address">{{ addr.address }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 撤销申请弹出框 -->
      <el-dialog :visible.sync="cancelDialogVisible" width="30%">
        <span>您仅能主动撤销一次,撤销后将不能再主动撤销,且超出保障期后无法再次发起售后</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="cancelDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { AfterSaleInfo } from '@/api/order'
export default {
  name:"refundDetails",
  data(){
    return{
      id:0,
      info:[],
      addr:[],
      loading:false,
      refundActive:1,
      refundStatus:0,
      statustext:'',
      statustime:'',
      cancelDialogVisible:false, //撤销申请弹出框
    }
  },
  methods: {
    async RefundInfo(){
      this.loading = true;
      const { data: res } = await AfterSaleInfo({id:this.id});
      this.loading = false;
      if (res.message == 'ok') {
        this.info  = res.result.info;
        this.addr  = res.result.addrlist;
        if(this.info.type==1) this.refundStatus = 1;
        if(this.info.type==2) this.refundStatus = 2;
        this.refundActive = this.info.status;
        this.StatusJudge();
      }else this.$router.push("/home/error");
    },
    StatusJudge(){
      if(this.info.type==1){
        if(this.info.status==1){this.statustext='等待商家同意';this.statustime=this.info.create_time;}
        if(this.info.status==2){this.statustext='商家已同意，等待系统退款';this.statustime=this.info.create_time;}
        if(this.info.status==3){this.statustext='退款成功';this.statustime=this.info.create_time;}
        if(this.info.status==7){this.statustext='商家不同意';this.statustime=this.info.create_time;}
      }
      if(this.info.type==2){
        if(this.info.status==1){this.statustext='等待卖家退货';this.statustime=this.info.create_time;}
        if(this.info.status==2){this.statustext='等待商家仓库确认收货';this.statustime=this.info.create_time;}
        if(this.info.status==3){this.statustext='商家已收货，等待审核';this.statustime=this.info.create_time;}
        if(this.info.status==4){this.statustext='商家已审核，等待系统退款';this.statustime=this.info.create_time;}
        if(this.info.status==5){this.statustext='退款成功';this.statustime=this.info.create_time;}
        if(this.info.status==7){this.statustext='商家不同意';this.statustime=this.info.create_time;}
      }
    },
    handleLogisticsNum(){ // 点击填写物流单号
      this.$router.push("/user/returns/logistics?id="+this.id)
    },
    revoke(){
      this.cancelDialogVisible = true   
    }
  },
  created() {
    this.id = this.$route.query.id;
    if (typeof this.id !== 'number' && isNaN(this.id)) { this.$router.push("/home/error"); return false; }
    this.RefundInfo();
  },
  filters: {
    refundOptions(val) {
      if (val == 1) return '不喜欢/不想要';
      if (val == 2) return '空包裹';
      if (val == 3) return '未按约定时间发货';
      if (val == 4) return '快递/物流一直未送到';
      if (val == 5) return '快递/物流无跟踪记录';
      if (val == 6) return '货物破损已拒签';
    },
    bothOptions(val) {
      if (val == 1) return '退运费';
      if (val == 2) return '大小/尺寸与商品描述不符';
      if (val == 3) return '颜色/型号/款式与商品描述不符';
      if (val == 4) return '材质与商品描述不符';
      if (val == 5) return '做工粗糙/有瑕疵';
      if (val == 6) return '质量问题';
      if (val == 7) return '配件问题';
      if (val == 8) return '少件/漏发';
      if (val == 9) return '包装/商品破损';
      if (val == 10) return '卖家发错货';
    },
  },
}
</script>

<style lang="less" scoped>
.refund-details {
  .d-container {
    width: 1000px;
    margin: 0 auto;
    .d-both {
      display: flex;
      justify-content: space-between;
      margin: 20px 0px;
      .h-item {
        width: 230px;
        height: 38px;
        background: #e1251b;
        display: flex;
        justify-content: center;
        align-items: center;
        .h-num {
          width: 20px;
          height: 20px;
          border: 1px solid #e1251b;
          border-radius: 50%;
          background: #fff;
          text-align: center;
          line-height: 20px;
          font-weight: 600;
          color: red;
          margin-right: 5px;
        }
        .h-text {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .d-refund {
      display: flex;
      justify-content: space-between;
      margin: 20px 0px;
      .h-item {
        width: 300px;
        height: 38px;
        background: #e1251b;
        display: flex;
        justify-content: center;
        align-items: center;
        .h-num {
          width: 20px;
          height: 20px;
          border: 1px solid #e1251b;
          border-radius: 50%;
          background: #fff;
          text-align: center;
          line-height: 20px;
          font-weight: 600;
          color: #e1251b;
          margin-right: 5px;
        }
        .h-text {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .d-content {
      border: 2px solid #ddd;
      min-height: 500px;
      margin-bottom: 20px;
      display: flex;
      .c-refund {
        width: 700px;
        padding: 40px 20px;
        border-right: 2px solid #ddd;
        min-height: 500px;
        font-size: 14px;
        .r-status {
          border-bottom: 1px solid #ddd;
          padding: 10px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .s-content {
            .s-text {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            .s-times {
              color: #6c6c6c;
            }
          }
          .s-btn {
            display: flex;
            line-height: 30px;
            cursor: pointer;
            font-size: 14px;
            text-align: center;
            .s-num {
              padding: 0px 20px;
              border: 1px solid #ddd;
              border-radius: 5px;
              color: #666;
              margin-right: 10px;
            }
            .s-cancel {
              padding: 0px 20px;
              border: 1px solid #e1251b;
              border-radius: 5px;
              background: #e1251b;
              color: #fff;
            }
          }
        }
        .r-money {
          height: 60px;
          line-height: 60px;
          font-size: 16px;
          .m-text {
            color: #e1251b;
          }
        }
        .c-progress {
          height: 180px;
          border: 1px solid #ddd;

          .p-txt {
            padding: 20px 0px 20px 20px;
          }
          .p-content {
            height: 120px;
            background: #fafafa;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .c-goods {
        width: 300px;
        padding: 20px;

        .g-title {
          line-height: 40px;
          font-size: 16px;
          border-bottom: 1px solid #ddd;
        }

        .g-content {
          height: 140px;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;

          .g-img {
            width: 54px;
            height: 54px;

            img {
              width: 54px;
              height: 54px;
            }
          }

          .g-goodsName {
            padding-left: 10px;

            .g-spec {
              margin-top: 5px;
              color: #666;
            }
          }
        }

        .g-order {
          padding: 10px 0px;
          display: flex;
          font-size: 13px;
          border-bottom: 1px solid #ddd;

          .o-title {
            margin-right: 20px;

            li {
              color: #666;
            }
          }
          .o-data {
            .o-item {
              color: #0088cb;
            }
          }
        }
        .g-refund {
          padding: 10px 0px;
          display: flex;
          font-size: 13px;
          .r-title {
            margin-right: 20px;
            li {
              color: #666;
            }
          }
        }
        .g-Logistics {
          padding: 10px 0px;
          font-size: 13px;
          display: flex;
          .l-title {
            margin-right: 20px;
            li {
              color: #666;
              width: 60px;
            }
          }
        }
      }
    }
    .el-dialog__wrapper {
      top: 20%;
    }
  }
}
</style>